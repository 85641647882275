export const navLinksdata = [
  {
    _id: 1001,
    title: "Home",
    link: "home",
  },

  {
    _id: 1003,
    title: "Projects",
    link: "projects",
  },
  {
    _id: 1004,
    title: "Resume",
    link: "resume",
  },
  {
    _id: 1006,
    title: "Contact",
    link: "contact",
  },
];